import { API } from 'aws-amplify';
import { 
  getUser as getUserCmd
} from '../graphql/queries';

const getUser = async (userId) => {
  try {
    const res = await API.graphql({
      query: getUserCmd,
      variables: {
        id: userId
      },
      authMode: 'AMAZON_COGNITO_USER_POOLS'
    });
    return {
      success: true,
      data: res.data.getUser
    }
  }
  catch (e) {
    return {
      success: false,
      error: e
    }
  }
}

export {
  getUser
}