import React from "react"
import { Auth } from "aws-amplify";
import { getUser } from "../apis/UserApi";
import { useLocation, useNavigate } from "react-router-dom";
import { ROUTE_PATHS } from "../constants";

const Context = React.createContext({});
export default function UserProvider ({children}) {
  /*** States and Variables ***/
  const [user, setUser] = React.useState(null);
  const location = useLocation();
  const navigate = useNavigate();
  /*** Processing ***/
  React.useEffect(()=>{
    const initUser = async () => {
      if (location.pathname===ROUTE_PATHS.LOGIN_PAGE || 
        location.pathname===ROUTE_PATHS.RESET_PASSWORD_PAGE) {
        return;
      }
      else if (location.pathname===ROUTE_PATHS.CHAT_PAGE || 
        location.pathname===ROUTE_PATHS.MODE_PAGE ||
        location.pathname===ROUTE_PATHS.CATALOG_LIST_PAGE || 
        location.pathname===ROUTE_PATHS.CATALOG_DETAIL) {
        if (user) return;
        try {
          const authRes = await Auth.currentAuthenticatedUser();
          if (authRes) {
            const _userId = authRes.attributes && authRes.attributes['sub'];
            const userRes = await getUser(_userId);
            if (userRes.success) {
              setUser(userRes.data);
            }
          }
          else {
            navigate(ROUTE_PATHS.LOGIN_PAGE);
          }
        }
        catch (e) {
          navigate(ROUTE_PATHS.LOGIN_PAGE);
        }
      }
      else {
        navigate(ROUTE_PATHS.LOGIN_PAGE);
      }
    }
    initUser();
  }, [location.pathname, user]);
  /*** Sub Components ***/
  /*** Event Handlers ***/
  /*** Main Render ***/
  return <Context.Provider value={{user, setUser}}>
    {children}
  </Context.Provider>
}

export const useUser = () => React.useContext(Context)