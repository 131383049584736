/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const findExternalConversationsByCityId = /* GraphQL */ `
  query FindExternalConversationsByCityId(
    $cityId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findExternalConversationsByCityId(
      cityId: $cityId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        createdAt
        evaluation
        id
        name
        ownerId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findExternalConversationsSortByEvaluation = /* GraphQL */ `
  query FindExternalConversationsSortByEvaluation(
    $cityId: ID!
    $evaluation: ModelStringKeyConditionInput
    $filter: ModelExternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findExternalConversationsSortByEvaluation(
      cityId: $cityId
      evaluation: $evaluation
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        createdAt
        evaluation
        id
        name
        ownerId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findExternalResourcesSortByStatus = /* GraphQL */ `
  query FindExternalResourcesSortByStatus(
    $filter: ModelExternalResourceFilterInput
    $governmentId: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $statusCreatedAt: ModelExternalResourceFindExternalResourcesSortByStatusCompositeKeyConditionInput
  ) {
    findExternalResourcesSortByStatus(
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      statusCreatedAt: $statusCreatedAt
    ) {
      items {
        answer
        createdAt
        governmentId
        id
        name
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findExternalResourcesSortByType = /* GraphQL */ `
  query FindExternalResourcesSortByType(
    $filter: ModelExternalResourceFilterInput
    $governmentId: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $typeCreatedAt: ModelExternalResourceFindExternalResourcesSortByTypeCompositeKeyConditionInput
  ) {
    findExternalResourcesSortByType(
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      typeCreatedAt: $typeCreatedAt
    ) {
      items {
        answer
        createdAt
        governmentId
        id
        name
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findInternalConversationsByCityId = /* GraphQL */ `
  query FindInternalConversationsByCityId(
    $cityId: ID!
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findInternalConversationsByCityId(
      cityId: $cityId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        catalogCreatedAt
        catalogType
        cityId
        createdAt
        evaluation
        id
        jsonData
        name
        ownerId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findInternalConversationsByEvaluation = /* GraphQL */ `
  query FindInternalConversationsByEvaluation(
    $cityId: ID!
    $evaluation: ModelStringKeyConditionInput
    $filter: ModelInternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findInternalConversationsByEvaluation(
      cityId: $cityId
      evaluation: $evaluation
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        catalogCreatedAt
        catalogType
        cityId
        createdAt
        evaluation
        id
        jsonData
        name
        ownerId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findInternalResourcesSortByStatus = /* GraphQL */ `
  query FindInternalResourcesSortByStatus(
    $filter: ModelInternalResourceFilterInput
    $governmentId: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $statusCreatedAt: ModelInternalResourceFindInternalResourcesSortByStatusCompositeKeyConditionInput
  ) {
    findInternalResourcesSortByStatus(
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      statusCreatedAt: $statusCreatedAt
    ) {
      items {
        answer
        createdAt
        governmentId
        id
        name
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findInternalResourcesSortByType = /* GraphQL */ `
  query FindInternalResourcesSortByType(
    $filter: ModelInternalResourceFilterInput
    $governmentId: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $typeCreatedAt: ModelInternalResourceFindInternalResourcesSortByTypeCompositeKeyConditionInput
  ) {
    findInternalResourcesSortByType(
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      typeCreatedAt: $typeCreatedAt
    ) {
      items {
        answer
        createdAt
        governmentId
        id
        name
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findUsersByGovernmentId = /* GraphQL */ `
  query FindUsersByGovernmentId(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelUserFilterInput
    $governmentId: ID!
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    findUsersByGovernmentId(
      createdAt: $createdAt
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        email
        enabled
        governmentId
        groups
        id
        isDeleted
        isVerified
        lastMessageAt
        messageCount
        status
        updatedAt
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const findWebPagesSortByStatus = /* GraphQL */ `
  query FindWebPagesSortByStatus(
    $baseUrlId: ID!
    $filter: ModelWebPageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $status: ModelStringKeyConditionInput
  ) {
    findWebPagesSortByStatus(
      baseUrlId: $baseUrlId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      status: $status
    ) {
      items {
        baseUrlId
        createdAt
        detailUrl
        id
        rejectReason
        status
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getAnalysis = /* GraphQL */ `
  query GetAnalysis($governmentId: ID!, $japanDay: String!) {
    getAnalysis(governmentId: $governmentId, japanDay: $japanDay) {
      createdAt
      external {
        totalRegisteredUsers
        __typename
      }
      externalMessage
      externalUserCount
      governmentId
      internal {
        totalRegisteredUsers
        __typename
      }
      internalMessage
      internalUserCount
      japanDay
      updatedAt
      __typename
    }
  }
`;
export const getCatalog = /* GraphQL */ `
  query GetCatalog(
    $createdAt: AWSDateTime!
    $governmentId: ID!
    $type: CatalogType!
  ) {
    getCatalog(
      createdAt: $createdAt
      governmentId: $governmentId
      type: $type
    ) {
      callCenterUrl
      categoryCode
      code
      createdAt
      excludeWordList
      governmentId
      id
      isActive
      isDisplayed
      jsonSchema
      llmVersersion
      maxTokens
      maxUrls
      noAnswerReply
      openSearchHost
      osBaseFilter
      osBaseIndex
      osBaseK
      promptAssistantContent
      promptDefaultTemplate
      promptTemplate
      queryGeneratorTemplate
      similarityBaseMinRate
      temperature
      title
      type
      uiSchema
      updatedAt
      __typename
    }
  }
`;
export const getCity = /* GraphQL */ `
  query GetCity($cityId: String!) {
    getCity(cityId: $cityId) {
      callcenterUrl
      cityId
      createdAt
      excludeUrlWord
      name
      noideaText
      pinconeIndex
      pineconeFilter
      pineconeSearchK
      prefix
      promptQueryUrl
      promptSearchWord
      promptTemplate
      updatedAt
      __typename
    }
  }
`;
export const getExternalConversation = /* GraphQL */ `
  query GetExternalConversation($createdAt: AWSDateTime!, $ownerId: ID!) {
    getExternalConversation(createdAt: $createdAt, ownerId: $ownerId) {
      cityId
      createdAt
      evaluation
      id
      name
      ownerId
      updatedAt
      __typename
    }
  }
`;
export const getExternalMessage = /* GraphQL */ `
  query GetExternalMessage($conversationId: ID!, $createdAt: AWSDateTime!) {
    getExternalMessage(conversationId: $conversationId, createdAt: $createdAt) {
      conversationId
      createdAt
      fixAnswerCreatedAt
      id
      like
      role
      text
      updatedAt
      url
      __typename
    }
  }
`;
export const getExternalResource = /* GraphQL */ `
  query GetExternalResource($createdAt: AWSDateTime!, $governmentId: ID!) {
    getExternalResource(createdAt: $createdAt, governmentId: $governmentId) {
      answer
      createdAt
      governmentId
      id
      name
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const getGovernment = /* GraphQL */ `
  query GetGovernment($governmentId: ID!) {
    getGovernment(governmentId: $governmentId) {
      createdAt
      externalMsgCount
      governmentId
      governmentName
      internalMsgCount
      ipWhitelist
      maxExternalMsgPerGovernment
      maxExternalMsgPerUser
      maxInternalMsgPerGovernment
      maxInternalMsgPerUser
      prefecture
      searchConditions {
        excludeExternalUrlWord
        excludeExternalWordList
        excludeInternalUrlWord
        excludeInternalWordList
        externalCallCenterUrl
        externalMaxUrls
        externalNoAnswerReply
        generativeDocumentTemplate
        governmentPrefix
        internalCallCenterUrl
        internalMaxUrls
        internalNoAnswerReply
        openSearchHost
        osExternalBaseFilter
        osExternalBaseIndex
        osExternalBaseK
        osExternalFaqFilter
        osExternalFaqIndex
        osExternalFaqK
        osInternalBaseFilter
        osInternalBaseIndex
        osInternalBaseK
        osInternalFaqFilter
        osInternalFaqIndex
        osInternalFaqK
        promptTemplate
        similarityExternalBaseMinRate
        similarityExternalFaqMinRate
        similarityInternalBaseMinRate
        similarityInternalFaqMinRate
        __typename
      }
      status
      updatedAt
      __typename
    }
  }
`;
export const getInternalConversation = /* GraphQL */ `
  query GetInternalConversation($createdAt: AWSDateTime!, $ownerId: ID!) {
    getInternalConversation(createdAt: $createdAt, ownerId: $ownerId) {
      catalogCreatedAt
      catalogType
      cityId
      createdAt
      evaluation
      id
      jsonData
      name
      owner {
        createdAt
        email
        enabled
        governmentId
        groups
        id
        isDeleted
        isVerified
        lastMessageAt
        messageCount
        status
        updatedAt
        username
        __typename
      }
      ownerId
      updatedAt
      __typename
    }
  }
`;
export const getInternalMessage = /* GraphQL */ `
  query GetInternalMessage($conversationId: ID!, $createdAt: AWSDateTime!) {
    getInternalMessage(conversationId: $conversationId, createdAt: $createdAt) {
      conversationId
      createdAt
      fixAnswerCreatedAt
      id
      like
      role
      text
      updatedAt
      url
      __typename
    }
  }
`;
export const getInternalResource = /* GraphQL */ `
  query GetInternalResource($createdAt: AWSDateTime!, $governmentId: ID!) {
    getInternalResource(createdAt: $createdAt, governmentId: $governmentId) {
      answer
      createdAt
      governmentId
      id
      name
      rejectReason
      srcMsgKey
      status
      type
      updatedAt
      url
      __typename
    }
  }
`;
export const getLineBot = /* GraphQL */ `
  query GetLineBot($botUserId: String!) {
    getLineBot(botUserId: $botUserId) {
      botUserId
      cityId
      createdAt
      lineChannelAccessToken
      lineChannelSecret
      updatedAt
      __typename
    }
  }
`;
export const getLineMessages = /* GraphQL */ `
  query GetLineMessages($id: ID!) {
    getLineMessages(id: $id) {
      content
      createdAt
      id
      lineUserId
      role
      updatedAt
      __typename
    }
  }
`;
export const getMonthlyAnalysis = /* GraphQL */ `
  query GetMonthlyAnalysis($governmentId: ID!, $japanMonth: String!) {
    getMonthlyAnalysis(governmentId: $governmentId, japanMonth: $japanMonth) {
      badEvalCount
      createdAt
      dislikeCount
      externalConversationCount
      externalUserCount
      goodEvalCount
      governmentId
      internalConversationCount
      internalUserCount
      japanMonth
      likeCount
      updatedAt
      __typename
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      createdAt
      email
      enabled
      governmentId
      groups
      id
      isDeleted
      isVerified
      lastMessageAt
      messageCount
      status
      updatedAt
      username
      __typename
    }
  }
`;
export const getWebPage = /* GraphQL */ `
  query GetWebPage($baseUrlId: ID!, $detailUrl: ID!) {
    getWebPage(baseUrlId: $baseUrlId, detailUrl: $detailUrl) {
      baseUrlId
      createdAt
      detailUrl
      id
      rejectReason
      status
      updatedAt
      __typename
    }
  }
`;
export const lineMessagesByLineUserIdAndCreatedAt = /* GraphQL */ `
  query LineMessagesByLineUserIdAndCreatedAt(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelLineMessagesFilterInput
    $limit: Int
    $lineUserId: String!
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    lineMessagesByLineUserIdAndCreatedAt(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      lineUserId: $lineUserId
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        content
        createdAt
        id
        lineUserId
        role
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listAnalyses = /* GraphQL */ `
  query ListAnalyses(
    $filter: ModelAnalysisFilterInput
    $governmentId: ID
    $japanDay: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listAnalyses(
      filter: $filter
      governmentId: $governmentId
      japanDay: $japanDay
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        externalMessage
        externalUserCount
        governmentId
        internalMessage
        internalUserCount
        japanDay
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCatalogs = /* GraphQL */ `
  query ListCatalogs(
    $filter: ModelCatalogFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
    $typeCreatedAt: ModelCatalogPrimaryCompositeKeyConditionInput
  ) {
    listCatalogs(
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
      typeCreatedAt: $typeCreatedAt
    ) {
      items {
        callCenterUrl
        categoryCode
        code
        createdAt
        excludeWordList
        governmentId
        id
        isActive
        isDisplayed
        jsonSchema
        llmVersersion
        maxTokens
        maxUrls
        noAnswerReply
        openSearchHost
        osBaseFilter
        osBaseIndex
        osBaseK
        promptAssistantContent
        promptDefaultTemplate
        promptTemplate
        queryGeneratorTemplate
        similarityBaseMinRate
        temperature
        title
        type
        uiSchema
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listCities = /* GraphQL */ `
  query ListCities(
    $cityId: String
    $filter: ModelCityFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCities(
      cityId: $cityId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        callcenterUrl
        cityId
        createdAt
        excludeUrlWord
        name
        noideaText
        pinconeIndex
        pineconeFilter
        pineconeSearchK
        prefix
        promptQueryUrl
        promptSearchWord
        promptTemplate
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listExternalConversations = /* GraphQL */ `
  query ListExternalConversations(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $ownerId: ID
    $sortDirection: ModelSortDirection
  ) {
    listExternalConversations(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ownerId: $ownerId
      sortDirection: $sortDirection
    ) {
      items {
        cityId
        createdAt
        evaluation
        id
        name
        ownerId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listExternalMessages = /* GraphQL */ `
  query ListExternalMessages(
    $conversationId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExternalMessages(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        conversationId
        createdAt
        fixAnswerCreatedAt
        id
        like
        role
        text
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listExternalResources = /* GraphQL */ `
  query ListExternalResources(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelExternalResourceFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listExternalResources(
      createdAt: $createdAt
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        answer
        createdAt
        governmentId
        id
        name
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listGovernments = /* GraphQL */ `
  query ListGovernments(
    $filter: ModelGovernmentFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listGovernments(
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        createdAt
        externalMsgCount
        governmentId
        governmentName
        internalMsgCount
        ipWhitelist
        maxExternalMsgPerGovernment
        maxExternalMsgPerUser
        maxInternalMsgPerGovernment
        maxInternalMsgPerUser
        prefecture
        status
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInternalConversations = /* GraphQL */ `
  query ListInternalConversations(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalConversationFilterInput
    $limit: Int
    $nextToken: String
    $ownerId: ID
    $sortDirection: ModelSortDirection
  ) {
    listInternalConversations(
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      ownerId: $ownerId
      sortDirection: $sortDirection
    ) {
      items {
        catalogCreatedAt
        catalogType
        cityId
        createdAt
        evaluation
        id
        jsonData
        name
        ownerId
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInternalMessages = /* GraphQL */ `
  query ListInternalMessages(
    $conversationId: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalMessageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInternalMessages(
      conversationId: $conversationId
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        conversationId
        createdAt
        fixAnswerCreatedAt
        id
        like
        role
        text
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listInternalResources = /* GraphQL */ `
  query ListInternalResources(
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelInternalResourceFilterInput
    $governmentId: ID
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listInternalResources(
      createdAt: $createdAt
      filter: $filter
      governmentId: $governmentId
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        answer
        createdAt
        governmentId
        id
        name
        rejectReason
        srcMsgKey
        status
        type
        updatedAt
        url
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLineBots = /* GraphQL */ `
  query ListLineBots(
    $botUserId: String
    $filter: ModelLineBotFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLineBots(
      botUserId: $botUserId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        botUserId
        cityId
        createdAt
        lineChannelAccessToken
        lineChannelSecret
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listLineMessages = /* GraphQL */ `
  query ListLineMessages(
    $filter: ModelLineMessagesFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLineMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        content
        createdAt
        id
        lineUserId
        role
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listMonthlyAnalyses = /* GraphQL */ `
  query ListMonthlyAnalyses(
    $filter: ModelMonthlyAnalysisFilterInput
    $governmentId: ID
    $japanMonth: ModelStringKeyConditionInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listMonthlyAnalyses(
      filter: $filter
      governmentId: $governmentId
      japanMonth: $japanMonth
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        badEvalCount
        createdAt
        dislikeCount
        externalConversationCount
        externalUserCount
        goodEvalCount
        governmentId
        internalConversationCount
        internalUserCount
        japanMonth
        likeCount
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        createdAt
        email
        enabled
        governmentId
        groups
        id
        isDeleted
        isVerified
        lastMessageAt
        messageCount
        status
        updatedAt
        username
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listWebPages = /* GraphQL */ `
  query ListWebPages(
    $baseUrlId: ID
    $detailUrl: ModelIDKeyConditionInput
    $filter: ModelWebPageFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listWebPages(
      baseUrlId: $baseUrlId
      detailUrl: $detailUrl
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        baseUrlId
        createdAt
        detailUrl
        id
        rejectReason
        status
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
