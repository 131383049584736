import React, {
  createContext,
  useContext,
} from 'react';
import FanLoadingIcon from '../components/FanLoadingIcon';

const Context = createContext({});

export default function LoadingProvider({ children }) {
  const [loading, setLoading] = React.useState(false);
  return <Context.Provider value={{loading, setLoading}}>
    {children}
    {loading && <FanLoadingIcon size={80}/>}
  </Context.Provider>
}
export const useLoading = () => useContext(Context);