/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-northeast-1",
    "aws_cloud_logic_custom": [
        {
            "name": "chatGPTLineChatBotRestApi",
            "endpoint": "https://oh38jc6vf0.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "userRestApi",
            "endpoint": "https://wjd33eqd78.execute-api.ap-northeast-1.amazonaws.com/production",
            "region": "ap-northeast-1"
        },
        {
            "name": "scrapebatch",
            "endpoint": "https://ju60x17j8d.execute-api.ap-northeast-1.amazonaws.com",
            "region": "ap-northeast-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://n3bc6r6elvdwrabnzvgdxjqt6i.appsync-api.ap-northeast-1.amazonaws.com/graphql",
    "aws_appsync_region": "ap-northeast-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-3ge24upefzf4zdyjvdsc3fveoe",
    "aws_cognito_identity_pool_id": "ap-northeast-1:6db14f22-55dd-4046-897a-2fa388b8ecfd",
    "aws_cognito_region": "ap-northeast-1",
    "aws_user_pools_id": "ap-northeast-1_CdKp8ufMI",
    "aws_user_pools_web_client_id": "inm66s62e5igeh7utbje4f06p",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "town-match-storage173616-production",
    "aws_user_files_s3_bucket_region": "ap-northeast-1"
};


export default awsmobile;
