import React, { Suspense, lazy} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import './App.css'

import DialogProvider from './contexts/DialogContext';
import LoadingProvider from './contexts/LoadingContext';
import UserProvider from './contexts/UserContext';
import FanLoadingIcon from './components/FanLoadingIcon';

const LoginPage = lazy(()=>import('./pages/LoginPage'));
const ResetPassword = lazy(()=>import('./pages/ResetPassword'));
const ModePage = lazy(()=>import('./pages/ModePage'));
const ChatPage = lazy(()=>import('./pages/ChatPage'));
function App() {
  return (
      <Router>
        <UserProvider>
          <DialogProvider>
            <LoadingProvider>
              <Routes>
                <Route path="/login" element={<Suspense fallback={<FanLoadingIcon size={80}/>}><LoginPage /></Suspense>} />
                <Route path="/auth/reset-password" element={<Suspense fallback={<FanLoadingIcon size={80}/>}><ResetPassword /></Suspense>} />
                <Route path="/mode" element={<Suspense fallback={<FanLoadingIcon size={80}/>}><ModePage /></Suspense>} />
                <Route path="/chat/*" element={<Suspense fallback={<FanLoadingIcon size={80}/>}><ChatPage /></Suspense>} />
                <Route path="*" element={<Suspense fallback={<FanLoadingIcon size={80}/>}><LoginPage /></Suspense>} />
              </Routes>
            </LoadingProvider>
          </DialogProvider>
        </UserProvider>
      </Router>
  );
}

export default App;
